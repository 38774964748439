import React from 'react';

import Layout from '../components/Layout';

function IndexPage() {
  return (
    <Layout fullMenu>
      <article id="main">
        <header>
          <h2>Not Found</h2>
          <p>Not a Valid URL</p>
        </header>
      </article>
    </Layout>
  );
}

export default IndexPage;
